.bg {
    /*background-image: url("../assets/images/bg_image_3.png");*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: 400px auto;
    background-color: #e3d3ee;
}

.tile_counter {
    max-width: 15rem;
    font-family: "Montserrat", sans-serif;
    color: white;
    display: flex;
    cursor: pointer;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden;
}

.tile_counter .icon {
    height: 5rem;
    width: 5rem;
    padding: 1rem;
    font-size: 2rem;
    line-height: 3.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.tile_counter .text {
    padding: 0.8rem;
    font-size: 1.3rem;
    line-height: 1.4rem;
    min-width: 6rem;
}

.tile_counter .text .number {
    margin-top: 0.4rem;
    font-weight: 600;
}

.tile_counter .text .name {
    font-size: 1rem;
    font-weight: 400;
}

.list_group_icon {
    width: 27px;
    color: var(--indigo);
    line-height: 2.5rem;
    text-align: center;
}