.notfound {
    max-width: 767px;
    /* width: 100%; */
    line-height: 1.4;
    padding: 0 15px
}

.notfound h1.notfound-404{
    font-family: 'Titillium Web', sans-serif;
    font-size: 186px;
    font-weight: 900;
    line-height: 150px;
    height: auto;
    margin-bottom: 25px;
    text-transform: uppercase;
    background: url(../../assets/images/text.png);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-size: auto;
    background-position: center
}

.notfound h2 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin: 0
}

.notfound p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin: 15px 0px;
    text-transform: uppercase
}

.notfound a {
    font-family: 'Titillium Web', sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    background: var(--sky);
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    -webkit-transition: .2s all;
    transition: .2s all;
}

.notfound a:hover {
    opacity: .8
}
