.jumbotron {
    background-color: rgba(233, 236, 239, 0.8);
    max-width: 1140px;
}

.signup {
    border-radius: 33px;
    color: white;
    /* background-color: #31a1fd; */
}

.bg {
    background-image: url("../assets/images/bg_image_3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 400px auto;
    background-color: #e3d3ee;
}
