:root {
    /* Cybersmarties Logo Colours HEX */
--indigo: #430094;
--sky: #00acf6;
--brinjal: #8d0072;
--lime: #79ce1e;
}

.bg-lime{
    background-color: var(--lime);
}

.bg-sky{
    background-color: var(--sky);
}

.bg-brinjal {
    background-color: var(--brinjal);
}

.bg-indigo {
    background-color: var(--indigo);
}

.bg-secondary-transparent {
    background-color: rgba(233, 236, 239, 0.8);
}

.btn.bg-indigo:hover {
    background-color: #55199E;
}

.text-brinjal {
    color: var(--brinjal);
}

.text-indigo {
    color: var(--indigo) !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

body {
    font-family: 'Muli', Arial, Tahoma, sans-serif;
    color: black;
}

.card-title, h1, h2, h3, .h1, .h2 {
    color: var(--brinjal);
}

.footer {
    display: block;
    /* width: 100%; */
    text-align: center;
    /* background-color: #4c288e; */
    background-color: var(--indigo);
    font-family: "Rubik", sans-serif;
    width: 100%;
}

.footer .copyright {
    max-width: 1140px;
    /* width: 100%; */
    /* margin: 0 auto; */
    /* font-weight: 400; */
}

.footer .copyright-text {
    color: #f4b3c1;
    font-size: 14px;
    letter-spacing: 1px;
    /* line-height: 16px; */
    /* float: left; */
    /* padding: 25px 0 20px; */
    opacity: 0.8;
}

.footer a:link {
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}
