.card {
    background-color: rgba(248, 249, 250, 0.9);
    /* background-color: rgba(233, 236, 239, 0.9); */
}

.card .img {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    height: 250px;
    object-fit: cover;
}

.card button[type="submit"] {
    max-width: 400px;
}

.card .maxWidth {
    max-width: 400px;
}

textarea.form-control {
    height: 100%;
}