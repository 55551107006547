.form-signin {
    max-width: 330px;
    padding: 1rem;
    position: relative;
}

.bg {
    position: relative !important ;
}

.bg::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(248, 249, 250, 0.9);
}

#formInputUsername {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

#formInputPassword {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-floating:focus-within {
    z-index: 2;
}
