.main_navbar .navbar-nav .active>.nav-link,
.main_navbar .navbar-nav .nav-link.active,
.main_navbar .navbar-nav .nav-link.show,
.main_navbar .navbar-nav .show>.nav-link {
  color: #FF6347;
}

.main_navbar .navbar-nav .nav-link {
  font-size: 20px;
}

.main_navbar #username_display.navbar-text {
  color: black;
  font-size: 20px;
  margin-right: 10px;
}

.main_navbar .nav-item .btn {
  margin-top: 0.2em;
}