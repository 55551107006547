.meatball-menu button.menu-toggle {
    width: 50%;
    max-width: 23px;
}
.meatball-menu ul.dropdown-menu {
    --bs-dropdown-link-hover-bg: var(--bs-primary);
    --bs-dropdown-link-hover-color: white;
    --bs-dropdown-link-color: rgba(0, 0, 0, 0.8);
    --bs-dropdown-item-padding-y: 0.5rem;
    --bs-dropdown-padding-y: 0rem;
    --bs-dropdown-border-radius: 0.49rem;
    --bs-dropdown-border-width: 0px;
    /* --bs-dropdown-bg: transparent; */
}
.meatball-menu ul.dropdown-menu li .dropdown-item {
    border: none;
    border-right: 1px solid var(--bs-secondary-border-subtle);
    border-left: 1px solid var(--bs-secondary-border-subtle);
}

.meatball-menu ul.dropdown-menu li:first-of-type .dropdown-item {
    border-top: 1px solid var(--bs-secondary-border-subtle);
    border-radius: 0.49rem 0.49rem 0 0;
}

.meatball-menu ul.dropdown-menu li:last-of-type .dropdown-item {
    border-bottom: 1px solid var(--bs-secondary-border-subtle);
    border-radius: 0 0 0.49rem 0.49rem;
}

.meatball-menu ul.dropdown-menu li .dropdown-item:hover {
    border-color: var(--bs-primary);
}
