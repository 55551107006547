.button {
    max-width: fit-content;
}

.responsive{
    padding-bottom: 60px;
    overflow-y: visible;
}

.student-list {
    --bs-table-bg: rgba(var(--bs-light-rgb), 0.9);
    --bs-border-width: 2px;
    border: 3px solid rgba(0, 0, 0, 0.175);
    border-collapse: separate;
    border-radius: 0.5rem;
    border-spacing: 1px;
    min-width: 600px;
    overflow: visible;
}

.student-list tbody tr:last-child > * {
    border-bottom-width: 0px;
}

.student-list tbody tr > * {
    padding: 0.8rem 0.5rem;
}

.student-list thead tr {
    --bs-table-bg: rgba(206, 212, 218, 0.9);
    border-radius: 10rem;
}

.student-list thead tr th:first-child {
    border-top-left-radius: 0.4rem;
}
.student-list thead tr th:last-child {
    border-top-right-radius: 0.4rem;
}
