.bg {
    /* The image used */
    /*background-image: url("./assets/images/bg_img_2.png");*/

    /* Center and scale the image nicely */
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: aliceblue;
}

.bg-light-transparent {
    background-color: rgba(255,255,255,0.7);
}

.card-bg-1 {
    background-color: rgba(var(--bs-light-rgb), 0.75);
}

.form-floating> textarea.form-control {
    min-height: 120px;;
}

div.react-select-bootstrap__control {
    padding: 9px 3px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 58px;
    color: var(--bs-body-color);
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

div.react-select-bootstrap__control--is-focused, div.react-select-bootstrap__control--is-focused:hover {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

div.react-select-bootstrap__multi-value__label {
  font-size: 100%;
}
